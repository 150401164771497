import $ from 'jquery';
global.$ = global.jQuery = $;
const Rellax = require('rellax');

/*
 * jQuery Easing v1.4.1 - http://gsgd.co.uk/sandbox/jquery/easing/
 * Open source under the BSD License.
 * Copyright © 2008 George McGinley Smith
 * All rights reserved.
 * https://raw.github.com/gdsmith/jquery-easing/master/LICENSE
*/
var pow = Math.pow,
  sqrt = Math.sqrt,
  sin = Math.sin,
  cos = Math.cos,
  PI = Math.PI,
  c1 = 1.70158,
  c2 = c1 * 1.525,
  c3 = c1 + 1,
  c4 = ( 2 * PI ) / 3,
  c5 = ( 2 * PI ) / 4.5;

// x is the fraction of animation progress, in the range 0..1
function bounceOut(x) {
  var n1 = 7.5625,
    d1 = 2.75;
  if ( x < 1/d1 ) {
    return n1*x*x;
  } else if ( x < 2/d1 ) {
    return n1*(x-=(1.5/d1))*x + 0.75;
  } else if ( x < 2.5/d1 ) {
    return n1*(x-=(2.25/d1))*x + 0.9375;
  } else {
    return n1*(x-=(2.625/d1))*x + 0.984375;
  }
}
$.extend( $.easing,
  {
    def: 'easeOutQuad',
    swing: function (x) {
      return $.easing[$.easing.def](x);
    },
    easeInQuad: function (x) {
      return x * x;
    },
    easeOutQuad: function (x) {
      return 1 - ( 1 - x ) * ( 1 - x );
    },
    easeInOutQuad: function (x) {
      return x < 0.5 ?
        2 * x * x :
        1 - pow( -2 * x + 2, 2 ) / 2;
    },
    easeInCubic: function (x) {
      return x * x * x;
    },
    easeOutCubic: function (x) {
      return 1 - pow( 1 - x, 3 );
    },
    easeInOutCubic: function (x) {
      return x < 0.5 ?
        4 * x * x * x :
        1 - pow( -2 * x + 2, 3 ) / 2;
    },
    easeInQuart: function (x) {
      return x * x * x * x;
    },
    easeOutQuart: function (x) {
      return 1 - pow( 1 - x, 4 );
    },
    easeInOutQuart: function (x) {
      return x < 0.5 ?
        8 * x * x * x * x :
        1 - pow( -2 * x + 2, 4 ) / 2;
    },
    easeInQuint: function (x) {
      return x * x * x * x * x;
    },
    easeOutQuint: function (x) {
      return 1 - pow( 1 - x, 5 );
    },
    easeInOutQuint: function (x) {
      return x < 0.5 ?
        16 * x * x * x * x * x :
        1 - pow( -2 * x + 2, 5 ) / 2;
    },
    easeInSine: function (x) {
      return 1 - cos( x * PI/2 );
    },
    easeOutSine: function (x) {
      return sin( x * PI/2 );
    },
    easeInOutSine: function (x) {
      return -( cos( PI * x ) - 1 ) / 2;
    },
    easeInExpo: function (x) {
      return x === 0 ? 0 : pow( 2, 10 * x - 10 );
    },
    easeOutExpo: function (x) {
      return x === 1 ? 1 : 1 - pow( 2, -10 * x );
    },
    easeInOutExpo: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        pow( 2, 20 * x - 10 ) / 2 :
        ( 2 - pow( 2, -20 * x + 10 ) ) / 2;
    },
    easeInCirc: function (x) {
      return 1 - sqrt( 1 - pow( x, 2 ) );
    },
    easeOutCirc: function (x) {
      return sqrt( 1 - pow( x - 1, 2 ) );
    },
    easeInOutCirc: function (x) {
      return x < 0.5 ?
        ( 1 - sqrt( 1 - pow( 2 * x, 2 ) ) ) / 2 :
        ( sqrt( 1 - pow( -2 * x + 2, 2 ) ) + 1 ) / 2;
    },
    easeInElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        -pow( 2, 10 * x - 10 ) * sin( ( x * 10 - 10.75 ) * c4 );
    },
    easeOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 :
        pow( 2, -10 * x ) * sin( ( x * 10 - 0.75 ) * c4 ) + 1;
    },
    easeInOutElastic: function (x) {
      return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
        -( pow( 2, 20 * x - 10 ) * sin( ( 20 * x - 11.125 ) * c5 )) / 2 :
        pow( 2, -20 * x + 10 ) * sin( ( 20 * x - 11.125 ) * c5 ) / 2 + 1;
    },
    easeInBack: function (x) {
      return c3 * x * x * x - c1 * x * x;
    },
    easeOutBack: function (x) {
      return 1 + c3 * pow( x - 1, 3 ) + c1 * pow( x - 1, 2 );
    },
    easeInOutBack: function (x) {
      return x < 0.5 ?
        ( pow( 2 * x, 2 ) * ( ( c2 + 1 ) * 2 * x - c2 ) ) / 2 :
        ( pow( 2 * x - 2, 2 ) *( ( c2 + 1 ) * ( x * 2 - 2 ) + c2 ) + 2 ) / 2;
    },
    easeInBounce: function (x) {
      return 1 - bounceOut( 1 - x );
    },
    easeOutBounce: bounceOut,
    easeInOutBounce: function (x) {
      return x < 0.5 ?
        ( 1 - bounceOut( 1 - 2 * x ) ) / 2 :
        ( 1 + bounceOut( 2 * x - 1 ) ) / 2;
    }
  });

var __sessionClear = window.location.href.split("?").pop().split("=")[1] === "true";
if(__sessionClear){
  //window.sessionStorage.removeItem("");
}

var _haveSession = false;
if(window.sessionStorage.getItem("") !== null){
  _haveSession = true;
}

global._isSP = undefined;

if(window.outerWidth < 829){
  _isSP = true;
}else{
  _isSP = false;
}

var Shuffle = function([...array]){
  for(var i = array.length - 1; i >= 0; i--){
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

var Planets = function(){
  var $planets = $(".planets");
  var planetNum = $planets.data("planet-number") | 0;
  var _planetsArr = [];
  for(var n = 1; n <= planetNum; n++){
    var _planet = $("<div class='planet'><img src='./assets/img/common/logo" + n + ".svg'></div>");
    _planetsArr.push(_planet);
  }
  _planetsArr = Shuffle(_planetsArr);
  _planetsArr.push($("<div class='planet planet--bg'><img src='./assets/img/common/bg_logo.svg'></div>"))
  for(var a = 0; a < _planetsArr.length; a++){
    $planets.append(_planetsArr[a]);
  }
};

var PreInit = function(){
  var commonPartsChecker = setInterval(function(){
    if($("header").length > 0 && $("footer").length){
      clearInterval(commonPartsChecker);
      Planets();
      Init();
    }
  }, 100);
};

var Init = function(){
  var docEml = document.documentElement;
  var $window = $(window);
  var $html = $("html");
  var $body = $("body").addClass("loaded");
  var $header = $("header");
  var $headerBtns = $header.find(".header-btns");
  var $nav = $("nav.nav");
  var $logo = $("#fv_logo");

  var _navContentdelayTime = 0;
  $nav.find(".wait").each(function(i){
    var _$this = $(this);
    _$this.attr("data-delay", 200);
    //_$this.attr("data-delay", i * _navContentdelayTime);
  });

  var $navButton = $(".header-nav--btn, .nav--close");
  var NavOpenClose = function(){
    //console.log("NavOpenClose");
    if($body.hasClass("nav--open")){
      $body.removeClass("is-modal");
      $nav.find('.nav-content').animate({ scrollTop: 0 }, 0);
      ResetAnimate($nav);
      $body.removeClass("nav--open");
      /*$nav.stop().animate({opacity: 0}, 200, function(){
        $nav.css({opacity: ""});
        $nav.find('.nav-content').animate({ scrollTop: 0 }, 0);
        ResetAnimate($nav);
        $body.removeClass("nav--open");
      });*/
    }else{
      if(_isSP){
        $body.addClass("nav--open");
        $body.addClass("is-modal");
        AnimationStart($nav);
      }
    }
  };
  $navButton.on("click", NavOpenClose);


  // ScrollAnimatiopn
  window.AnimationStart = function($container){
    // console.log("AnimationStart");
    var _$wait = $container.find(".wait");
    _$wait.each(function(){
      var __$this = $(this);
      var __delay = __$this.data("delay");
      var __timeout = setTimeout(function(){
        __$this
          .removeClass("wait")
          .addClass("animation");
        clearTimeout(__timeout);
      }, __delay)
    });
  };

  window.ResetAnimate = function($container){
    // console.log("ResetAnimate");
    var _$animate = $container.find(".animation");
    var _$animating = $container.find(".animating");
    _$animate.each(function(){
      var __$this = $(this);
      __$this
        .addClass("wait")
        .removeClass("animation");
    });
    _$animating.each(function(){
      var __$this = $(this);
      __$this
        .addClass("waiting")
        .removeClass("animating");
    });

  };

  var SetAnimation = function(){
    var $section = $(".waiting");
    var sectionLen = $section.length;
    var timing, _height, mainHeight, mainTop;
    _height = $window.height();
    mainTop = 0;
    timing = _height - (_height / 5);

    for(var i = 0; i < sectionLen; i++){
      var $wait = $($section[i]);
      var offsetTop;
      offsetTop = $wait.offset().top;

      if($window.scrollTop() + timing >= offsetTop && $window.scrollTop() <= offsetTop){
        var _delay = $wait.data("delay");
        if(_delay === void 0){
          _delay = 0;
        }
        $wait.delay(_delay).queue(function(){
          var __$this = $(this);
          __$this.removeClass("waiting").addClass('animating');
          __$this.dequeue();
        });
      }
    }
    if(sectionLen === 0){
      $window.off("scroll", AnimationScroller);
    }
  };

  var AnimationScroller = function(){
    SetAnimation();

    if($body.css("opacity") !== "1"){
      $body.css({opacity: 1});
    }
  };
  $window.on("scroll", AnimationScroller);
  AnimationScroller();

  $(".pagetop").on("click", function(){
    $("html, body").stop().animate({scrollTop: 0}, 1000, "easeInOutCirc");
  });

  $(".anchor").on("click", function(){
    var _$this = $(this);
    var _href = _$this.attr("href");
    if(_href !== "" || _href !== "#"){
      var _$target = $(_href);
      $("html, body").stop().animate({scrollTop: _$target.offset().top}, 1000, "easeInOutCirc");
      NavOpenClose();
    }
    return false;
  });

  var Resize = function(){
    if($window.width() <= 828){
      _isSP = true;
    }else{
      _isSP = false;
      if($body.hasClass("nav--open")){
        $body.removeClass("nav--open");
        $body.removeClass("is-modal");
      }
    }
  };
  $window.on("resize", Resize);
  Resize();

  var rellax = new Rellax('.rellax', {});

  var NavColorChange = function(){
    var _pos = $logo.offset().top + $logo.outerHeight();
    if($window.scrollTop() > _pos){
      if(!$header.hasClass("header--scrolled")){
        $header.addClass("header--scrolled");
      }
    }else{
      if($header.hasClass("header--scrolled")){
        $header.removeClass("header--scrolled");
      }
    }
  };
  $window.on("scroll", NavColorChange);
  NavColorChange();

  var _hash = location.hash.replace('_anchor','');
  var $scrollTarget = $(_hash);
  if($scrollTarget.length > 0){
    $("html, body").stop().animate({scrollTop: $scrollTarget.offset().top - $header.outerHeight()}, 1000, "easeInOutCirc");
  }

  var SelectModalOpen = function(event){
    // console.log("SelectModalOpen");
    var _$target = $(event.currentTarget);
    var _parentId = _$target.data("modal-id");
    var _id = _$target.data("modal-content");
    var $modal = $("#" + _parentId);
    var $modalOverlay = $modal.find(".modal-overlay");
    var $modalBox = $modal.find(".modal-box");
    var $modalClose = $modal.find(".modal--close, .close");
    var $modalContents = $modal.find(".modal-content");
    $html.addClass("is-modal");
    // console.log("SelectModalOpen");
    $modalContents.each(function(){
      var __$this = $(this);
      if(__$this.data("modal-content") === _id){
        var _url = __$this.data("modal-url");
        var _color = __$this.data("modal-color");
        var _overlay = __$this.data("modal-overlay");
        var _opacity = __$this.data("modal-opacity") * 1;
        var _youtube = _$target.data("modal-youtube");

        if(_url !== void 0){
          __$this.css({
            "background": "url(" + _url + ") center center / 100% 100%"
          });
        }else if(_color !== void 0){
          __$this.css({
            "background": _color
          });
        }

        if(_youtube !== void 0){
          var __$movie = __$this.find(".movie").empty();
          var __$yt = $('<iframe width="560" height="315" src="https://www.youtube.com/embed/' + _youtube + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
          __$movie.append(__$yt);
        }

        __$this.addClass("modal-content--open");
        $modalOverlay.css({
          "background": _overlay,
          opacity: _opacity,
        });
      }
    });

    $modal.css({display: "block"});
    $(".modal-wrap").scrollTop(0);
    if($window.height() > $modalBox.height()){
      //$modalBox.addClass("position-middle");
    }else{
      //$modalBox.removeClass("position-middle");
    }
    //$modalOverlay.on("click", SelectModalClose);
    $modalClose.on("click", SelectModalClose);
  };

  var SelectModalClose = function(event){
    var _$target = $(event.currentTarget);
    var _parentId = _$target.data("modal-id");
    var $modal = $("#" + _parentId);
    var $modalOverlay = $modal.find(".modal-overlay");
    var $modalClose = $modal.find(".modal--close, .close");
    var $modalBox = $modal.find(".modal-box");
    var $modalContents = $modal.find(".modal-content");
    var _youtube = $modalContents.data("modal-youtube");
    $modalContents.find(".movie").empty();
    $modal.css({display: ""});
    $html.removeClass("is-modal");
    $modalContents.each(function(){
      var __$this = $(this);
      __$this
        .css({"background": ""})
        .removeClass("modal-content--open");
    });
    $modalOverlay.css({
      "background": "",
      opacity: "",
    });

    $modalBox.removeClass("position-middle");

    //$modalOverlay.off("click", SelectModalClose);
    $modalClose.off("click", SelectModalClose);

    $("#term_check").prop('checked', false);
  };

  $body.on("click", ".modal--btn", SelectModalOpen);

};

window.addEventListener("load", PreInit);

